<template>
    <div class="flex-col page">
        <Wapheader />
        <div class="head-banner">
            <div class="head-banner-text">联系我们</div>
        </div>
        <div class="mapBox">
            <el-amap vid="amapDemo" :zoom="zoom" :center="center">
                <el-amap-marker v-for="marker in markers" :position="marker.position" :events="marker.events"
                    :key="marker.index"></el-amap-marker>
                <el-amap-info-window v-for="window in windows" :offset="window.offset" :position="window.position"
                    :content="window.content" :open="window.open" :key="window.index"></el-amap-info-window>
            </el-amap>
        </div>
        <div class="flex-col mapBox-text" >
            <div class="mapBox-text-title">杭州英捷鑫科技有限公司</div>
            <div>浙江省杭州市余杭区良渚街道金昌路2800号<br/>洋帆中心1号楼12层</div>
            <div class="mapBox-text-title" style="margin-top: 20px;">杭州英捷鑫科技有限公司 常州分公司</div>
            <div>江苏省常州市新北区吕汤路10号</div>
        </div>
        <div class="lx-img">
            <img src="../../assets/images/lx/sj/img1.png" alt="">
        </div>
        <div class="lx-content">
            <div class="items-center justify-between lx-item">
                <div class="lx-item-left">服务热线</div>
                <div class="lx-item-right">400-808-0395</div>
            </div>
            <div class="jmsq-title">加盟申请</div>
            <div class="items-center justify-between lx-item">
                <div class="lx-item-left">电动车营销部联系电话</div>
                <div class="lx-item-right">0519-68197852</div>
            </div>
            <div class="items-center justify-between lx-item mt30">
                <div class="lx-item-left">联系邮箱</div>
                <div class="lx-item-right">ev@mail.kymco.com.cn</div>
            </div>
        </div>
        <span class="yysj" @click="navPath('/newwapyy')">预约试驾 了解更多</span>
        <Wapfooter />
    </div>
</template>
<script>
export default {
    data() {
        return {
            unit: "",
            address: "",
            markers: [],
            windows: [],
            center: [120.140739, 30.34828],
            zoom: 10,
        }
    },
    
    mounted() {
        this.initMap();
    },
    methods: {
        // 初始化地图
        initMap(map) {
            this.markers.push({
                position: [120.140739, 30.34828],
            });

            this.windows.push({
                position: [120.140739, 30.34828],
                content:
                    "",
                offset: [0, -20],
                open: true,
            });
            // var map = new AMap.Map("amapDemo", {
            //   zoomEnable: true, //是否禁止缩放
            //   zoom: 12, //缩放比例
            //   dragEnable: false, //禁止拖动
            //   cursor: "hand", // 鼠标在地图上的表现形式，小手
            // });
            // // 初始化工具条
            // map.plugin(["AMap.ToolBar"], function () {
            //   map.addControl(new AMap.ToolBar());
            // });
        },

    }
}
</script>
<style lang="less" scoped>
.head-banner-text{
    font-size: 48px;
    color: #fff;
    text-align: center;
    margin-top: 180px;
}
.yysj{
    display: block;
    margin: 0 auto;
    margin-top: 100px !important;
    font-size: 24px;
    line-height: 64.5px;
    padding-bottom: 15px;
    border-bottom: 1px solid #1A1A1A;
    margin-bottom: 320px !important;
    cursor: pointer;
}
.mt30{
    margin-top: 30px;
}
.lx-content {
    padding: 70px 50px;
    background: #fff;
    width: 90%;
    margin: 0 auto;

    .jmsq-title {
        font-size: 28px;
        margin-top: 55px;
        margin-bottom: 40px;
    }

    .lx-item {
        font-size: 24px;
        padding-bottom: 10px;
        border-bottom: 1px solid #090909;

        .lx-item-left {
            font-family: 'Albb';
        }

        .lx-item-right {
            font-family: 'Albbr';
        }
    }
}

.lx-img {
    margin-top: 140px !important;
    width: 90%;
    margin: 0 auto;

    img {
        height: 650px;
    }
}

.mapBox-text {
    width: 90%;
    padding: 44px 0;
    margin: 0 auto;
    text-align: center;
    background: #fff;
    font-size: 24px;

    .mapBox-text-title {
        font-family: 'Albb';
        font-size: 28px;
    }
}

.mapBox {
    position: relative;
    z-index: 11;
    width: 90%;
    height: 600px;
    margin: 0 auto;
    margin-top: -275px;
}

.page {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 100vh;
    background: #F5F5F5;
}

.head-banner {
    width: 100%;
    height: 540px;
    background-image: url(../../assets/images/lx/sj/head-banner.png);
    background-size: 100% 100%;
}
</style>